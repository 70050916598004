






































import {Vue, Component} from 'vue-property-decorator';
import {UserModule} from '@/store/modules/user';
import {ApplicationModule} from '@/store/modules/application';
import {ExpositionModule} from '@/store/modules/exposition';
import {IFicheExpositionData} from '@/entities/fiche-exposition/fiche-exposition.types';

import ExpositionCard from '@/components/User/ExpositionCard.vue';

@Component({
    name: 'FichesExposition',
    components: {ExpositionCard}
})
export default class extends Vue {
    private today: string = new Date().toISOString().substr(0, 10);
    private bShowDetailsDialog = false;

    private focus = '';

    private mounted() {
        (this.$refs.calendar as any).scrollToTime({hour: 8, minute: 0});
        this.refreshEvent(this.today);
    }

    private updateRange({start}: any) {
        this.refreshEvent(`${start.date}T00:00:00`);
    }

    private async refreshEvent(dateStr: string) {
        if (!UserModule.authenticated || !ApplicationModule.online) {
            return;
        }
        ExpositionModule.getFichesExposition(dateStr);
    }

    private getEventColor(event: any) {
        const fiche = event.event as IFicheExpositionData;
        if (fiche.possedeMasque) {
            return 'primary';
        }
        return 'green';
    }

    private showFicheDetails({nativeEvent, event}: {nativeEvent: Event; event: any}) {
        this.$store.commit('exposition/SET_SELECTEDID', (event.event as IFicheExpositionData).id);
        this.bShowDetailsDialog = true;

        nativeEvent.stopPropagation();
    }

    private get events() {
        const tFiches = ExpositionModule.temporaryFiches;
        if (Array.isArray(tFiches) && tFiches.length > 0) {
            const now = new Date();
            const timeZoneOffset = now.getTimezoneOffset();
            return tFiches.map(element => {
                const updatedSt = new Date(
                    (element.dateEntreeEnZone as Date).getTime() - timeZoneOffset * 60000
                );
                const updatedEnd = new Date(
                    (element.dateSortieDeZone as Date).getTime() - timeZoneOffset * 60000
                );
                return {
                    name: "Fiche d'exposition",
                    start: updatedSt.toISOString().substr(0, 16),
                    end: updatedEnd.toISOString().substr(0, 16),
                    event: element
                };
            });
        }
        return [];
    }
}

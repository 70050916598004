






































































































































































































import {Vue, Component, PropSync} from 'vue-property-decorator';
import {ApplicationModule} from '@/store/modules/application';
import {ExpositionModule} from '@/store/modules/exposition';
import filters from '@/modules/filters';
import {
    MaskType,
    FicheExpoEPI,
    allEpis,
    FicheExpoActivite,
    allActivites,
    FicheExpoAgentChimique,
    allAgentsChimiques
} from '@/entities/fiche-exposition/fiche-exposition.types';

import SvgIcon from '@/components/SvgIcon.vue';

@Component({
    components: {SvgIcon},
    filters
})
export default class extends Vue {
    @PropSync('showDialog') private syncedShowDialog!: boolean;

    private get loading() {
        return ApplicationModule.loading;
    }

    private get selected() {
        return ExpositionModule.selectedFiche;
    }

    private get dateEntree() {
        if (!this.selected?.dateEntreeEnZone) {
            return undefined;
        }
        return this.selected.dateEntreeEnZone;
    }

    private get dateSortie() {
        if (!this.selected?.dateSortieDeZone) {
            return undefined;
        }
        return this.selected.dateSortieDeZone;
    }

    private get tempsEnZone() {
        const tempsEnZone = this.selected?.tempsEnZoneEnSeconde || 0;
        const hours = Math.floor(tempsEnZone / 3600);
        const minuts = Math.floor((tempsEnZone - hours * 3600) / 60);
        const seconds = tempsEnZone - hours * 3600 - minuts * 60;

        return `${hours > 0 ? `${hours}h ` : ''}${minuts > 0 ? `${minuts}m et ` : ''}${seconds}s`;
    }

    private get possedeMasque() {
        return this.selected?.possedeMasque ?? false;
    }

    private get masque() {
        return (this.$i18n.t('masque').valueOf() as string[])[
            this.selected?.masque || MaskType.Aucun
        ];
    }

    private get temperature() {
        const temperature = this.selected?.temperature;
        if (temperature !== undefined) {
            return `${temperature} °C`;
        }
        return '-';
    }

    private get tauxEmpoussierement() {
        const txEmp = this.selected?.tauxEmpoussierement;
        if (txEmp !== undefined) {
            return `${txEmp} f/l`;
        }
        return '-';
    }

    private getEpiIcon(value: string) {
        switch (value) {
            case 'Combinaison':
                return 'combi';
            case 'Gants':
                return 'gant';
            case 'Bottes':
                return 'botte';
            case 'SousVetements':
                return 'sous_vetement';
            case 'DoubleCombi':
                return 'double_combi';
            case 'SurBottes':
                return 'sur_botte';
            case 'Lunettes':
                return 'lunette';
            case 'Casque':
                return 'casque';
            case 'Harnais':
                return 'harnais';
            case 'AntiBruit':
                return 'antibruit';
            default:
                return 'combi';
        }
    }

    private getActiviteIcon(value: string) {
        switch (value) {
            case 'Confinement':
                return 'confinement';
            case 'Replis':
                return 'repli4';
            case 'Aspiration':
                return 'aspiration';
            case 'Depoussierement':
                return 'depoussierage';
            case 'NettoyageFin':
                return 'nettoyage_fin';
            case 'Retrait':
                return 'retrait';
            case 'EvacuationDechets':
                return 'dechets';
            case 'InstallationGenerale':
                return 'installation_generale';
            case 'RepliGeneral':
                return 'repli_general';
            default:
                return 'confinement';
        }
    }

    private get epis() {
        if ((this.selected?.etape || -1) < 5) {
            return [];
        }
        // eslint-disable-next-line no-underscore-dangle
        const _epis: {
            [idx: string]: FicheExpoEPI;
        } = FicheExpoEPI as any;
        // tslint:disable-next-line:no-bitwise
        return allEpis()
            .map(element => {
                if (((this.selected?.epi || 0) & _epis[element.value]) !== _epis[element.value]) {
                    return null;
                }
                return {
                    text: element.text,
                    value: element.value
                };
            })
            .filter(obj => obj !== null);
    }

    private get activites() {
        if ((this.selected?.etape || -1) < 5) {
            return [];
        }
        // eslint-disable-next-line no-underscore-dangle
        const _activites: {
            [idx: string]: FicheExpoActivite;
        } = FicheExpoActivite as any;
        // tslint:disable-next-line:no-bitwise
        return allActivites()
            .map(element => {
                if (
                    ((this.selected?.activites || 0) & _activites[element.value]) !==
                    _activites[element.value]
                ) {
                    return null;
                }
                return {
                    text: element.text,
                    value: element.value
                };
            })
            .filter(obj => obj !== null);
    }

    private get agentsChimiques() {
        if ((this.selected?.etape || -1) < 5) {
            return [];
        }
        // eslint-disable-next-line no-underscore-dangle
        const _agents: {
            [idx: string]: FicheExpoAgentChimique;
        } = FicheExpoAgentChimique as any;
        // tslint:disable-next-line:no-bitwise
        return allAgentsChimiques()
            .map(element => {
                if (
                    ((this.selected?.agentsChimiques || 0) & _agents[element.value]) !==
                    _agents[element.value]
                ) {
                    return null;
                }
                return {
                    text: element.text,
                    value: element.value
                };
            })
            .filter(obj => obj !== null);
    }
}
